import React from "react";
import { useOverrides, Override, StackItem, Stack } from "@quarkly/components";
import { Text, Section } from "@quarkly/widgets";
import Calculator from "./Calculator";
const defaultProps = {
	"sm-padding": "80px 0 40px 0",
	"padding": "120px 0 40px 0",
	"background": "url(https://uploads.quarkly.io/5f844e532e3a2a001faf55b8/images/head-13.svg?v=2020-10-20T19:28:03.243Z) center -896px/3610px no-repeat,linear-gradient(180deg,#F5F5F5 21.6%,rgba(251, 251, 251, 0.21) 100%)",
	"md-padding": "100px 0 40px 0"
};
const overrides = {
	"stack": {
		"kind": "Stack",
		"props": {
			"margin": "0px 0px 0px 0px"
		}
	},
	"stackItem": {
		"kind": "StackItem",
		"props": {
			"width": "100%",
			"display": "flex",
			"sm-align-items": "center",
			"sm-width": "100%",
			"lg-width": "100%"
		}
	},
	"stackItemOverride": {
		"kind": "Override",
		"props": {
			"slot": "StackItemContent",
			"justify-content": "flex-start",
			"align-items": "flex-start",
			"sm-align-items": "center",
			"lg-align-items": "center",
			"flex-direction": "column"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"sm-font": "normal 700 34px/30px Inter, sans-serif",
			"font": "normal 700 48px/58px --fontFamily-googleInter",
			"margin": "0px 0px 36px 5px",
			"display": "inline-block",
			"color": "rgba(19, 33, 42, 1)",
			"letter-spacing": "-1%",
			"md-font": "normal 700 30px/40px Inter, sans-serif",
			"sm-text-align": "center",
			"children": "Purchase"
		}
	},
	"calculator": {
		"kind": "Calculator",
		"props": {
			"display": "flex",
			"align-items": "flex-start",
			"justify-content": "flex-start",
			"nout-width": "100%",
			"border-radius": "6px",
			"width": "75%"
		}
	}
};

const Tarifs = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" max-width="1200px" />
		<Stack {...override("stack")}>
			{"    "}
			<StackItem {...override("stackItem")}>
				<Override {...override("stackItemOverride")} />
				{"        "}
				<Text {...override("text")} />
				{"    "}
			</StackItem>
			{"    "}
		</Stack>
		{children}
	</Section>;
};

Object.assign(Tarifs, { ...Section,
	defaultProps,
	overrides
});
export default Tarifs;